// components/Video.jsx
import JWPlayer from "@/shared/JWPlayer";
import { JWPlayerContainer, JWText } from "./style";

const Video = ({
  content,
  liveBlogAutoPlay = true,
  sectionIdJWPlayerEmbed,
  sectionIdJWPlayerEmbedUpright,
  elementBodyId
}) => {
  const {
    name,
    description,
    entityId,
    thumbnail,
    metadata,
    epigraph = null,    
  } = content?.value || {};

  const isVertical = metadata?.typeWebView?.toLowerCase() === "vertical";
  const orientationClass = isVertical ? "vertical" : "horizontal";  
  const title = epigraph || description;
  const extraClass = content?.customData?.selectedView ? ` ${content?.customData?.selectedView?.toLowerCase()}` : "";
  // Seleccionar el script adecuado basado en la orientación
  let playerScript = sectionIdJWPlayerEmbed || process.env.NEXT_PUBLIC_EMBEB_JWPLAYER_ID;

  if (isVertical) {
    if (sectionIdJWPlayerEmbedUpright) {
      playerScript = sectionIdJWPlayerEmbedUpright;
    } else {
      playerScript = process.env.NEXT_PUBLIC_JWPLAYER_VERTICAL_ID; // Script exclusivo para vertical
    }
  }

  // Generar un ID único para cada reproductor
  const playerId = `jwplayer-${entityId || Math.random().toString(36).substr(2, 9)}`;
  return (
    <JWPlayerContainer className={`border-video video-embed ${orientationClass}${extraClass}`} isEspecial={extraClass.trim() === "especial"} id={elementBodyId}>
      <JWPlayer
        id={playerId}
        entityId={entityId}
        playerScript={playerScript}
        thumbnail={thumbnail}
        title={name}
        type="embeb"
        autostart={liveBlogAutoPlay}
      />
      <JWText className="videoText">
        <h2>Video</h2>
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
      </JWText>
    </JWPlayerContainer>
  );
};

export default Video;
